import { jsxs as t, Fragment as m, jsx as r } from "react/jsx-runtime";
import { Avatar as c } from "primereact/avatar";
import { AvatarGroup as d } from "primereact/avatargroup";
import h from "../hooks/useConnectedViews.js";
import "@croquet/react";
import "react";
import "./ReactTogether.js";
import "../object_hash-1efe2599.js";
function v({
  maxAvatars: l = 3,
  debug: p = !1
}) {
  const i = h();
  if (!i)
    return;
  const s = "normal", n = Math.max(i.length - l, 0);
  return /* @__PURE__ */ t(m, { children: [
    /* @__PURE__ */ t(d, { pt: { root: { style: { gap: "10px" } } }, children: [
      i.slice(0, l).map(({ name: e, viewId: o }) => /* @__PURE__ */ r(
        c,
        {
          image: `https://api.dicebear.com/8.x/initials/svg?seed=${e}`,
          size: s,
          shape: "circle",
          label: e,
          pt: { root: { title: e } }
        },
        o
      )),
      n > 0 && /* @__PURE__ */ r(c, { label: `+${n}`, shape: "circle", size: s })
    ] }),
    p && /* @__PURE__ */ t("div", { style: { textAlign: "left" }, children: [
      /* @__PURE__ */ r("p", { children: "Connected users:" }),
      /* @__PURE__ */ r("ul", { children: i.map(({ viewId: e, name: o, isYou: a }) => /* @__PURE__ */ r("li", { children: a ? /* @__PURE__ */ t("strong", { children: [
        e,
        ": ",
        o
      ] }) : /* @__PURE__ */ t("span", { children: [
        e,
        ": ",
        o
      ] }) }, e)) })
    ] })
  ] });
}
export {
  v as default
};
