import { CroquetContext as b } from "@croquet/react";
import { useContext as m, useState as p, useEffect as h, useCallback as g } from "react";
import C from "./getNewValue.js";
function q(t, a, S) {
  const u = m(b);
  let e = null, l = null, s = null;
  u !== void 0 && (e = u.model, l = u.session, s = u.view);
  const c = e == null ? void 0 : e.state.get(t), [d, i] = p(() => s && e && !e.state.has(t) ? (s.publish(e.id, "setState", { id: t, newValue: a }), a) : c);
  h(() => {
    if (!l || !s || !e)
      return;
    const n = () => {
      const o = e.state.get(t);
      i((r) => r === o ? r : o);
    };
    return s.subscribe(
      t,
      { event: "updated", handling: "oncePerFrame" },
      n
    ), () => {
      try {
        s.unsubscribe(t, "updated", n);
      } catch (o) {
        console.error("Failed to unsubscribe:", o);
      }
    };
  }, [l, s, e, t, i]);
  const f = g(
    (n) => {
      if (e && s) {
        const o = e.state.get(t);
        s.publish(e.id, "setState", {
          id: t,
          newValue: C(o, n)
        });
      } else
        i(n);
    },
    [i, e, s, t]
  );
  return [d, f];
}
export {
  q as default
};
