import { CroquetContext as C } from "@croquet/react";
import { h as d } from "../object_hash-1efe2599.js";
import { useState as w, useContext as x, useEffect as I, useCallback as q } from "react";
import b from "./getNewValue.js";
function m(s) {
  const c = {};
  return s.forEach((u, i) => c[i] = u), c;
}
function A(s, c) {
  const [u] = w(c), i = x(C);
  let t = null, h = null, e = null;
  i !== void 0 && (t = i.model, h = i.session, e = i.view);
  const n = (e == null ? void 0 : e.viewId) || "", [v, f] = w(() => {
    const a = m(
      (t == null ? void 0 : t.stateTogether.get(s)) || /* @__PURE__ */ new Map([[n, u]])
    ), o = d(a);
    return { value: a, hash: o };
  }), { value: V } = v;
  I(() => {
    if (!h || !e || !t || !n)
      return;
    const a = () => {
      const o = m(
        t.stateTogether.get(s)
      ), l = d(o);
      f((r) => r.hash === l ? r : { value: o, hash: l });
    };
    return e.subscribe(
      s,
      { event: "updated", handling: "oncePerFrame" },
      a
    ), e.publish(t.id, "setStateTogether", {
      id: s,
      viewId: n,
      newValue: u
    }), () => {
      e.publish(t.id, "setStateTogether", {
        id: s,
        viewId: n,
        newValue: void 0
      }), e.unsubscribe(s, "updated", a);
    };
  }, [h, e, n, t, s, u]);
  const S = V[n] || u, T = q(
    (a) => {
      if (t && e) {
        const l = t.stateTogether.get(s).get(e.viewId), r = b(l, a);
        e.publish(t.id, "setStateTogether", {
          id: s,
          viewId: n,
          newValue: r
        });
      } else
        f((o) => {
          const { value: l, hash: r } = o, g = b(l[n], a), p = d(g);
          return r === p ? o : (l[n] = g, { value: l, hash: p });
        });
    },
    [e, n, t, s]
  );
  return [S, T, V];
}
export {
  A as default
};
