import { jsx as s, Fragment as h } from "react/jsx-runtime";
import { CroquetRoot as v } from "@croquet/react";
import { createContext as S, useState as l } from "react";
import T from "../models/ReactTogetherModel.js";
const x = S(void 0);
function _({
  children: n,
  sessionParams: t
}) {
  const { appId: c, apiKey: d, sessionIgnoresUrl: m } = t, [o, i] = l(
    // ({}).VITE_CROQUET_NAME || null
    "Test session"
  ), [e, r] = l(
    // ({}).VITE_CROQUET_PASSWORD || null
    "123"
  ), p = o !== null && e !== null, u = () => {
    const f = "v0_session", g = "pwd";
    i(f), r(g);
  }, w = () => {
    i(null), r(null);
  };
  let a;
  return m || (a = {
    sessionUrl: window.location.origin + window.location.pathname
  }), /* @__PURE__ */ s(
    x.Provider,
    {
      value: {
        createNewSession: u,
        leaveSession: w,
        isTogether: p,
        sessionName: o,
        sessionPassword: e
      },
      children: o !== null && e !== null ? /* @__PURE__ */ s(
        v,
        {
          sessionParams: {
            model: t.model || T,
            appId: c,
            apiKey: d,
            name: o,
            password: e,
            options: a
          },
          children: /* @__PURE__ */ s(h, { children: n })
        }
      ) : n
    }
  );
}
export {
  x as ReactTogetherContext,
  _ as default
};
