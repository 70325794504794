import { useViewId as m } from "@croquet/react";
import { useRef as d, useEffect as h } from "react";
import "unique-names-generator";
import "../components/ReactTogether.js";
import p from "./useStateTogetherWithPerUserValues.js";
function P(s, f = {}) {
  const { highlightMyself: l = !1 } = f, a = m(), o = d(null), [, r, v] = p(s, !1);
  h(() => {
    const e = o.current;
    if (!e)
      return;
    const t = (i) => {
      const u = i.rtProcessedBy;
      u === void 0 ? (r(!0), i.rtProcessedBy = s) : u !== s && r(!1);
    }, n = () => r(!1);
    return e.addEventListener("mouseover", t), e.addEventListener("mouseleave", n), () => {
      e.removeEventListener("mouseover", t), e.removeEventListener("mouseleave", n);
    };
  }, [r, s]);
  const c = Object.entries(v).filter(
    ([e, t]) => t && (e !== a || l)
  ).map(([e]) => e);
  return [o, c];
}
export {
  P as default
};
