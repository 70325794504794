import { useConnectedViews as n, useViewId as r } from "@croquet/react";
import { uniqueNamesGenerator as a, adjectives as i, animals as o } from "unique-names-generator";
function m() {
  const { views: s } = n(), t = r();
  return Array.from(s).map((e) => ({
    viewId: e,
    isYou: e === t,
    name: a({
      seed: e,
      dictionaries: [i, o],
      length: 2,
      separator: " ",
      style: "capital"
    })
  }));
}
export {
  m as default
};
